import React, { FunctionComponent } from "react";

import Layout from "../common/layout/layout";
import SEO from "../common/seo";

const ThanksPage: FunctionComponent = () => (
  <Layout>
    <SEO title="Thanks" />
    <p>
      Thanks for contacting us.
      <br />
      We will be in touch with you shortly.
    </p>
  </Layout>
);

export default ThanksPage;
